import React from "react";
import "./Manual.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage } from "gatsby-plugin-intl";

//ikonice
import max from "../assets/images/max.png";
import time from "../assets/images/time.png";
import alc from "../assets/images/alc.png";
import water from "../assets/images/water.png";
import doza from "../assets/images/doza.png";
import oprez from "../assets/images/oprez.png";
import cuvaj from "../assets/images/cuvaj.png";

const Manual = () => {
  return (
    <section className="wrapper special style0">
      <h2>
        <FormattedMessage id="manual_naslov" />
      </h2>
      <Tabs>
        <TabList>
          <Tab>
            <FormattedMessage id="manual_tab1" />
          </Tab>
          <Tab>
            <FormattedMessage id="manual_tab2" />
          </Tab>
          <Tab>
            <FormattedMessage id="manual_tab3" />
          </Tab>
          <Tab>
            <FormattedMessage id="manual_tab4" />
          </Tab>
        </TabList>

        <TabPanel>
          <div className="row">
            <div>
              <img src={max} style={{ width: 100 }} alt="Max" />
              <p className="opis">
                {" "}
                <FormattedMessage id="tab1_stavka1" />
              </p>
            </div>
            <div>
              <img src={time} style={{ width: 100 }} alt="Time" />
              <p className="opis">
                <FormattedMessage id="tab1_stavka2" />
              </p>
            </div>
          </div>
          <div className="row">
            <div>
              <img src={alc} style={{ width: 100 }} alt="No Alcohol" />
              <p className="opis">
                <FormattedMessage id="tab1_stavka3" />
              </p>
            </div>
            <div>
              <img src={water} style={{ width: 100 }} alt="No water" />
              <p className="opis">
                <FormattedMessage id="tab1_stavka4" />
              </p>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <table>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka1" />
                </td>
                <td>g/100g</td>
                <td>83,05</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka2" />
                </td>
                <td>g/100g</td>
                <td>1,38</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka3" />
                </td>
                <td>g/100g</td>
                <td>1,75</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka4" />
                </td>
                <td>g/100g</td>
                <td>78,80</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka5" />
                </td>
                <td>KJ/kcal/100g</td>
                <td>1420,4/338,2</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka6" /> / <br />{" "}
                  <FormattedMessage id="tabela_stavka6.1" />
                </td>
                <td>g/100g</td>
                <td>0,25</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka7" />
                </td>
                <td>g/100g</td>
                <td>33,50</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="tabela_stavka8" />
                </td>
                <td>g/100g</td>
                <td>0,24</td>
              </tr>
            </table>
          </div>
          <p style={{ marginTop: 50 }}>
            <FormattedMessage id="tabela_recenica1" /> <br />
            <FormattedMessage id="tabela_recenica2" />
          </p>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div>
              <img src={doza} style={{ width: 120 }} alt="Measure" />
              <p className="opis">
                {" "}
                <FormattedMessage id="tab3_stavka1" />
              </p>
            </div>
            <div>
              <img src={oprez} style={{ width: 120 }} alt="Caution" />
              <p className="opis">
                {" "}
                <FormattedMessage id="tab3_stavka2" />
              </p>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row">
            <div>
              <img src={cuvaj} style={{ width: 120 }} alt="Protect" />
              <p>
                {" "}
                <FormattedMessage id="tab4_stavka" />
              </p>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </section>
  );
};

export default Manual;
