import React from "react";
import Mail from "../components/Mail";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";

import Layout from "../components/Layout";
import Manual from "../components/Manual";

import problemi from "../assets/images/problemi.jpg";
import vatra_logo from '../assets/images/vatra_logo.png'
import kutija from "../assets/images/vatra2019.png";

const IndexPage = () => {
  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <h2>
            <div className="image">
              <img className="vatra_logo" src={vatra_logo} alt="Vatra" width="250" />
            </div>
          </h2>
          <h1>
            <br />
            <span className="vatra_podnaslov"><FormattedMessage id="sekcija1_podnaslov" /></span>
            <br />
          </h1>
          <ul className="actions special">
            <li>
              <a
                href="https://www.abcnatur.sk/product/vatra/"
                target="_blank"
                rel="noopener noreferrer"
                className="button primary"
              >
                <FormattedMessage id="sekcija1_dugmeKupi" />
              </a>
            </li>
          </ul>
        </div>
        <a
          href="https://www.abcnatur.sk/product/vatra/"
          target="_blank"
          rel="noopener noreferrer"
          className="more"
        >
          <FormattedMessage id="sekcija1_strelicaDole" />
        </a>
      </section>

      <section className="spotlight special style0">
        <div className="image">
          <img src={problemi} alt="Problems" />
        </div>
        <div className="content">
          <h3 style={{ color: "white" }}>
            <FormattedMessage id="sekcija2_naslov1" />
            <br />
            <FormattedMessage id="sekcija2_naslov2" />
            <br />
            <FormattedMessage id="sekcija2_naslov3" />
            <br />
            <FormattedMessage id="sekcija2_naslov4" />
            <br />
          </h3>
        </div>
      </section>

      <section id="one" className="wrapper special spotlight style0">
        <div className="inner">
          <header className="major">
            <h2>
              <span><FormattedMessage id="sekcija3_naslov" /></span>
              <br />
              <span>  <FormattedMessage id="sekcija3_podnaslov" /> </span>
            </h2>
            <br />
            <div className="image">
              <img src={kutija} alt="Vatra" width="250" />
            </div>
            <p>
              <FormattedMessage id="sekcija3_ispodSlike1" />
              <br />
              <FormattedMessage id="sekcija3_ispodSlike2" />
              <br />
              <FormattedMessage id="sekcija3_ispodSlike3" />
              <br />
              <FormattedMessage id="sekcija3_ispodSlike4" />
              <br />
              <FormattedMessage id="sekcija3_ispodSlike5" />
            </p>
          </header>
          <ul className="icons major">
            <li>
              <span className="icon fa-flag major style0" />
              <br />
              <br />
              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <FormattedMessage id="sekcija3_ikonica1" /> <br />{" "}
                <FormattedMessage id="sekcija3_ikonica1.1" />
              </p>
            </li>
            <li>
              <span className="icon fa-clock major style0" />
              <br />
              <br />
              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <FormattedMessage id="sekcija3_ikonica2" /> <br />{" "}
                <FormattedMessage id="sekcija3_ikonica2.1" />
              </p>
            </li>
            <li>
              <span className="icon solid fa-fire major style0" />
              <br />
              <br />
              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <FormattedMessage id="sekcija3_ikonica3" /> <br />{" "}
                <FormattedMessage id="sekcija3_ikonica3.1" />
              </p>
            </li>
          </ul>
          <ul className="icons major">
            <li>
              <span className="icon fa-smile major style0" />
              <br />
              <br />
              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <FormattedMessage id="sekcija3_ikonica4" /> <br />{" "}
                <FormattedMessage id="sekcija3_ikonica4.1" />
              </p>
            </li>
            <li>
              <span className="icon fa-heart major style0">
                <span className="label">Ipsum</span>
              </span>
              <br />
              <br />
              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <FormattedMessage id="sekcija3_ikonica5" /> <br />{" "}
                <FormattedMessage id="sekcija3_ikonica5.1" />
              </p>
            </li>
            <li>
              <span className="icon fa-hourglass major style0">
                <span className="label">Dolor</span>
              </span>
              <br />

              <p style={{ fontSize: "14px", marginTop: "25px" }}>
                <br />
                <FormattedMessage id="sekcija3_ikonica6" /> <br />{" "}
                <span>&nbsp;</span>
              </p>
            </li>
          </ul>
          <ul className="actions special">
            <li>
              <a
                href="https://www.abcnatur.sk/product/vatra/"
                target="_blank"
                rel="noopener noreferrer"
                className="button secondary"
              >
                <FormattedMessage id="sekcija3_dugmeKupi" />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="three" className="wrapper style2 special">
        <div className="inner">
          <header className="major">
            <h2>
              <FormattedMessage id="sekcija4_naslov" />
            </h2>
            <p>
              <FormattedMessage id="sekcija4_tekst1" />
              <br />
              <FormattedMessage id="sekcija4_tekst2" />
              <br />
              <FormattedMessage id="sekcija4_tekst3" />
              <br />
              <FormattedMessage id="sekcija4_tekst4" />
            </p>
          </header>
          <ul className="features">
            <li className="icon fa-paper-plane style0">
              <h3>
                <FormattedMessage id="sekcija4_ikonica1" />
              </h3>
              <p>
                <FormattedMessage id="sekcija4_opis1" />
                <br />
                <FormattedMessage id="sekcija4_opis1.1" />
                <br />
                <FormattedMessage id="sekcija4_opis1.2" />
                <br />
                <FormattedMessage id="sekcija4_opis1.3" />
              </p>
            </li>
            <li className="icon solid fa-check">
              <h3>
                {" "}
                <FormattedMessage id="sekcija4_ikonica2" />
              </h3>
              <p>
                <FormattedMessage id="sekcija4_opis2" />
              </p>
            </li>
            <li className="icon solid fa-thumbs-up style0">
              <h3>
                {" "}
                <FormattedMessage id="sekcija4_ikonica3" />
              </h3>
              <p>
                <FormattedMessage id="sekcija4_opis3" />
                {/* Výživový doplnok VATRA , spĺňa najvyššie hľadiska kvality.
                <br />
                Povolenie Ministerstva zdravotníctva Slovenskej republiky.
                <br />
                Pravidelná kontrola kvality.
                <br />
                Prírodné zloženie bylinných afrodiziák bez chemikálií
                <br />
                Vhodný na dlhodobé užívanie.
                <br />
                Žiadne nežiaduce účinky u užívateľov, ktorí používaju gel VATRA */}
              </p>
            </li>

            <li className="icon solid fa-seedling style0">
              <h3>
                {" "}
                <FormattedMessage id="sekcija4_ikonica4" />
              </h3>
              <p>
                <FormattedMessage id="sekcija4_opis4" />
                <br />
                <FormattedMessage id="sekcija4_opis4.1" />
              </p>
            </li>
          </ul>
        </div>
      </section>

      <Manual />

      <section id="cta" className="wrapper special style0">
        <header>
          <h2>
            {" "}
            <FormattedMessage id="sekcija5_naslov" />
          </h2>
        </header>
        <ul className="actions special">
          <li>
            <a
              href="https://www.abcnatur.sk/product/vatra/"
              target="_blank"
              rel="noopener noreferrer"
              className="button primary"
            >
              <FormattedMessage id="sekcija5_dugmeKupi" />
            </a>
          </li>
        </ul>
      </section>

      <section className="wrapper special style0">
        <header>
          <h3>
            <FormattedMessage id="sekcija5_naslovZaSubscribe" />
          </h3>
        </header>
        <ul className="actions special">
          <Mail />
        </ul>
      </section>
    </Layout>
  );
};

export default injectIntl(IndexPage);
