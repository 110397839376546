import React from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";


class IndexPage extends React.Component {
  state = {
    email: '',
    response: []
  };

  handleChange = event => {
    this.setState({ email: event.target.value });
  };

  showResponseMessage = ({ msg, result }) =>
    result === "success"
      ? this.setState({ response: msg })
      : this.setState({ response: msg });

  onSuccess = data => {
    this.setState(this.showResponseMessage(data));
    document.getElementById("mailchimp-form").reset();
  };

  handleSubmit = e => {
    if (!this.canBeSubmitted()) {
      return;
    }

    e.preventDefault();
    addToMailchimp(this.state.email)
      .then(this.onSuccess)
      .catch(err => {
        console.log("err", err);
      });
  }

  canBeSubmitted = () => {
    const { email } = this.state;
    return email && email.length > 0;
  }


  render() {
    const isEnabled = this.canBeSubmitted();

    return (
      <div>
        <div>
          <form id="mailchimp-form" onSubmit={this.handleSubmit}>
            <FormattedMessage id="kontakt_email">
              {txt =>
                <input
                  type="email"
                  onChange={this.handleChange}
                  placeholder={txt}
                  name="email"
                />
              }
            </FormattedMessage>

            <br />
            <input disabled={!isEnabled} type="submit" value="Subscribe" />

            <div style={{ marginTop: 15 }}>{this.state.response}</div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectIntl(IndexPage);